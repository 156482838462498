import type { JSX } from 'react';
import { Team } from '@/components';
import { Content } from '@prismicio/client';
import { SliceComponentProps } from '@prismicio/react';

export type TeamProps = SliceComponentProps<Content.TeamSlice>;

const TeamSlice = ({ slice }: TeamProps): JSX.Element => {
  return <Team {...slice} />;
};

export default TeamSlice;
